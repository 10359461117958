html, body {
    margin: 0;
    height: 100%;
}

.form-signin {
  max-width: 400px;
  padding: 15px;
}

.form-signup{
  max-width: 400px;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#root {
  height: 100vh;
}

main {
    padding-bottom:10px;
}

.form-control-dark {
    border-color: var(--bs-gray);
  }
.form-control-dark:focus {
    border-color: #fff;
    box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .25);
}

.text-small {
    font-size: 85%;
}

.dropdown-toggle {
    outline: 0;
}

